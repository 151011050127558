@import "mixins";
@import "variables";

.contact-page {
  &__title {
    padding: 0 19% 20px 0;
    @include font-lg;
    font-weight: 600;
    @include media-tablet {
      padding-bottom: 70px;
    }
  }

  &__contacts-block {
    &__title {
      padding: 0 19% 20px 0;
      margin-bottom: 20px;
      @include font-lg;
      font-weight: 600;
      border-bottom: 1px solid $color-primary;

      @include media-mobile {
        padding-right: 5%;
      }
    }

    &__subtitle {
      padding: 0 19% 40px 0;
      @include font-md;
    }
  }
}
