@import 'variables';
@import 'mixins';

.header {
  width: 100%;
  position: absolute;
  padding: 24px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include media-mobile {
    flex-wrap: wrap;
  }

  @include media-tablet {
    padding: 24px 20px;
  }

  &--inverted {
    .header__nav {
      a {
        color: $color-inverted;
      }
    }

    .header__nav-mobile {
      a {
        color: $color-inverted;
        border-bottom: 1px solid $color-inverted;
      }
    }
  }

  &--blue {
    .btn--header {
      color: $bg-color-blue;
      &:hover {
        background: transparent;
      }
    }
  }

  &--red {
    .btn--header {
      color: $color-red;
      &:hover {
        background: transparent;
      }
    }
  }

  &--dark {
    .btn--header {
      color: $bg-color-dark;
      &:hover {
        background: transparent;
      }
    }
  }

  &__logo {
    margin-right: auto;
    display: flex;
    align-items: center;
  }
  &__nav {
    a {
      padding-right: 30px;

      &.active {
        font-style: italic;
      }
    }
  }

  &__mobile-menu {
    height: 100vh;
    width: 100%;
    position: fixed;
    padding: 24px 20px;
    background: $bg-color-primary;
    z-index: 100;
  }

  &__nav-mobile {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;

    a {
      width: 100%;
      padding: 30px 0;
      border-bottom: 1px solid $color-primary;
      @include font-lg;
      font-weight: 600;

      &.active {
        font-style: italic;
      }
    }
  }

  .btn--header {
    padding: 12px 40px;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: normal;
  }

  &__close-icon {
    position: absolute;
    right: 23px;
    top: 35px;
    cursor: pointer;
  }

  &__menu-icon {
    cursor: pointer;
  }

}
