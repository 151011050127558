@font-face {
  font-family: "SuisseIntl";
  src: url("assets/fonts/SuisseIntl-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("assets/fonts/SuisseIntl-RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("assets/fonts/SuisseIntl-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("assets/fonts/SuisseIntl-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
