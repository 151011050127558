@import 'variables';

.footer {
  position: absolute;
  bottom: 0;
  padding: 30px;
  font-weight: 600;

  &--inverted {
    color: $color-inverted;
  }
}
