@mixin media-mobile {
  @media only screen and (max-width: 740px) {
    @content;
  }
};

@mixin media-desktop {
  @media only screen and (min-width: 1001px) {
    @content;
  }
};

@mixin media-tablet {
  @media only screen and (max-width: 1000px) {
    @content;
  }
};

@mixin font-base() {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.03em;

  @include media-mobile {
    font-size: 13px;
  }
}

@mixin font-sm() {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.03em;

  @include media-mobile {
    font-size: 11px;
    line-height: 11px;
    letter-spacing: -0.02em;
  }
}

@mixin font-md() {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.03em;

  @include media-mobile {
    font-size: 13px;
    line-height: 15px;
  }
}

@mixin font-lg() {
  font-size: 60px;
  line-height: 56px;
  letter-spacing: -0.05em;

  @include media-mobile {
    font-size: 40px;
    line-height: 40px;
  }
}

@mixin font-xl() {
  font-size: 100px;
  line-height: 94px;
  letter-spacing: -0.05em;

  @include media-mobile {
    font-size: 50px;
    line-height: 50px;
  }
}

@mixin font-btn() {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.03em;

  @include media-mobile {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: normal;
  }
}
